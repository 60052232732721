<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2"> Monitoring Laporan IKP</h3>
          </template>
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            :detailData="detailData"
            :forceUpdateTable="forceUpdate"
            @onTableChange="updateTable"
          >
          <template v-slot:append-header>
            <v-spacer></v-spacer>
            <v-select label="SKPD" hide-details></v-select>
          </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      forceUpdate:false,
      loading:false,
      detailData:{},

      headers:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Instruksi', value:'instruksi', sortable:false, class:'header-index-profile'},
        {text:'Leading Sector', value:'leading', sortable:false, class:'header-index-profile'},
        {text:'Supporting', value:'support', sortable:false, class:'header-index-profile'},
        {text:'Waktu Lapor Terakhir', value:'waktu', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      items:[{
        no:1, instruksi:'Test Kegiatan', leading:'TS/123003/DSDF-DIKES/DF/DF332Q3', support:'Menajalankan dan memantau test kegiatan', waktu:'2020-02-20 20:20:20'
      }],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:true,
        enableCreate:false,
        enableDelete:false,
        enableEdit:false,
      },
    }
  },

  watch:{},

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
